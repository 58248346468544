//Signal settings component render when the user click the setting button in sidebar component
import { useState, useEffect } from "react";
import "../../css/MainViews.scss";
import TitleIcon from "../../assets/personal-information.png";
import Back from "../../assets/back.png";
import Power from "../../assets/power.svg";
import InfoIcon from "../../assets/info.png";
import { useTranslation } from "react-i18next";
import swal from "sweetalert2";
import Tooltip from '@mui/material/Tooltip';


function SubAccountInformationForm(props) {  //This function gets props from sidebar component

    //-----------------------------------------------------------
    //Declaration of constants, variables and hooks
    //-----------------------------------------------------------

    const [t] = useTranslation("global");
    const [ddProt, setDdProt] = useState(""); 
    //Validates if the settings component was called by the user by click on settings button, this adds an extra tag to the classname
    //to show or not the component
    let menu = props.triggerMenu ? 'open' : 'closed';

    var labelPnl = document.getElementById("pnl");

    // Condicional para definir el color del texto
    try{
        if (props.subAccountData.pnl < 0) {
            labelPnl.style.color = "#FF4862"; // Establece el color rojo
        } else if(props.subAccountData.pnl > 0) {
            labelPnl.style.color = "#09B87D"; // Establece el color azul
        }
        else{
            labelPnl.style.color = "gray"; // Establece el color azul
        }
    }catch{};
    
    //-----------------------------------------------------------
    //Functions and validations area
    //-----------------------------------------------------------

    //UseEffect hook triggered everytime the prop masterData, sends by sidebar, is changed. This hook upload the SignalSettings (ddProt)
    //state that contains all the follower settings information.
    useEffect(() => {
        setDdProt(props.subAccountData);
    }, [props.subAccountData]);

    //Function uses to show alert modals in many parts of the component
    async function AlertMessageSelection(title, message) {       
        const result = await swal.fire({
            title: t(title),
            text: t(message),
            icon: "warning",
            iconColor: "orange",
            color: "#363636",
            background: "#FFFFFF",
            showCancelButton: true,
            cancelButtonText: t("master.Cancel"),
            cancelButtonColor: "#3085d6",
            confirmButtonText: t("subAccount.Yes"),
            confirmButtonColor: "#FF0000"
        });
        return result;
    };
    
   
    return (
        <div className={`                     
                      ${'formContainer-' + menu}
                  `} id='settingContainer'>   
            <form className='settingForm' id="settingForm" >  
                <div className='informationContainer'>               
                    <div className='titleContainer'>
                        <div className='titleSetting'>
                            <img
                                className='imageTitle'
                                src={TitleIcon}
                                alt="title"
                                htmlFor="title-icon"
                                ></img>
                            <title>{t("subAccount.Account's Information")}</title>                       
                        </div> 
                        <button className='backIcon' type='button' onClick={props.onClose}>
                            <img
                                src={Back}
                                alt="back Icon"
                                htmlFor="back-icon" />
                        </button>
                    </div> 
                    <div className='labelField'>
                        <label className='settingLabel'>{t("master.Login")}:</label>
                    <label className='infoLabel'>{props.subAccountData.account}</label>            
                    </div>       
                    <div className='labelField'>
                        <label className='settingLabel'>{t("subAccount.Account's Name")}:</label>
                    <label className='infoLabel'>{props.subAccountData.name}</label>   
                    </div>
                    <div className='labelField'>
                        <label className='settingLabel'>{t("master.Leverage")}:</label>
                    <label className='infoLabel'>{props.subAccountData.leverage}</label>  
                    </div>
                    <div className='labelField'>
                        <label className='settingLabel'>{t("master.Currency")}:</label>                
                    <label className='infoLabel'>{props.subAccountData.currency}</label>
                    </div>
                    <div className='labelField'>
                        <label className='settingLabel'>{t("master.Balance")}:</label>                    
                    <label className='infoLabel'>{props.subAccountData.balance}</label>  
                    </div>
                    <div className='labelField'>
                        <label className='settingLabel'>{t("master.Credit")}:</label>                    
                    <label className='infoLabel'>{props.subAccountData.credit !== undefined ? props.subAccountData.credit : 0}</label>  
                    </div>
                    <div className='labelField'>
                        <label className='settingLabel'>{t("master.Equity")}:</label>   
                    <label className='infoLabel'>{props.subAccountData.equity}</label>  
                    </div>
                    <div className='labelField'>
                        <label className='settingLabel'>{t("master.Margin")}:</label>   
                    <label className='infoLabel'>{props.subAccountData.margin}</label>  
                    </div>
                    <div className='labelField'>
                        <label className='settingLabel'>{t("master.Free_Margin")}:</label>   
                    <label className='infoLabel'>{props.subAccountData.free_margin}</label>  
                    </div>
                    <div className='labelField'>
                        <label className='settingLabel'>{t("master.PnL")}:</label>                  
                    <label className='infoLabel'  id='pnl'>{props.subAccountData.pnl}</label>     
                    </div>
                </div>
                <div className="allocationContainer">                
                    <div className='titleContainer'>
                        <div className='titleSetting'>
                            <img
                                className='imageTitle'
                                src={TitleIcon}
                                alt="title"
                                htmlFor="title-icon"
                                ></img>
                            <title>{t("master.Allocation Details")}</title>                       
                        </div> 
                    </div> 
                    <div className='labelField'>
                        <label className='settingLabel'>{t("master.Client Group")}:</label>
                    <label className='infoLabel'>{props.subAccountData.group}</label>            
                    </div>       
                    <div className='labelField'>
                        <label className='settingLabel'>{t("master.Allocation Type")}:</label>
                    <label className='infoLabel'>{props.subAccountData.allocation === "Cash_Equity"? "Cash Equity" : "Equity"}</label>  
                    </div>
                </div>
            </form>            
        </div>
    );

}

export default SubAccountInformationForm;