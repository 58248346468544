//Signal settings log component rendered when Signal user click on Historical settings button in signa main view
import 'bootstrap/dist/css/bootstrap.min.css'
import DataTable from 'react-data-table-component'
import '../../css/DataTable.scss'
import { useTranslation } from "react-i18next";
import { useState } from "react";
import downloadIcon from "../../assets/DownIcon.png"

function SignalLogsTable(props) {

    //-----------------------------------------------------------
    //Declaration of constants, variables and hooks
    //-----------------------------------------------------------

    const [loginSub, setloginSub] = useState("");
    const [filterValue, setFilterText] = useState('');
    const { t } = useTranslation("global");
    const tabla_data = props.subAccountList;
    let filteredData = [];

    const filterValueLowercase = filterValue.toLowerCase();

    const handleFilterChange = (e) => {
        const value = e.target.value || '';
        setFilterText(value);
      };

    if(tabla_data){
    
        filteredData = tabla_data.filter(
            (row) => {
            const columnsToValidate = [
                'login',
                'name',
                'balance',
                'credit',
                'equity',
                'margin',
                'free_margin',
                'profit',
                'agent',
                'leverage',
                ];
            
                return columnsToValidate.some((column) => {
                const value = row[column];
            
                if (typeof value === 'number' && !isNaN(value)) {

                    return value.toString().toLowerCase().includes(filterValueLowercase);
                }
            
                if (typeof value === 'string' && value.toLowerCase().includes(filterValueLowercase)) {
                    return true; // Filtrar si es una cadena que incluye el valor de filtro
                }
            
                return false; // No filtrar en otros casos
                });
        });
    }
    const customStyles = {
      rows: {
          style: {
              minHeight: '36px', // override the row height
          },
      },
    }
    //The columnas constant contains an array of all the columns that the history table will have and its cells settings and styles
    const columnas = [
        {
            name: <div className="title-format">{t("master.Login")}</div>,
            selector: row => row['login'],
            sortable: true,
            center: true,
            wrap: true,
            style: {
                color: 'black',
            },
            conditionalCellStyles: [    
                //group of validations to sett if this cell belongs to the signal provider followed        
                { when: row => row.login === loginSub, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }}
            ],
        },
        {
            name: <div className="title-format">{t("master.Name")}</div>,
            selector: row => row['name'],
            sortable: true,
            center: true,
            wrap: true,
            compact: true,
            conditionalCellStyles: [    
                //group of validations to sett if this cell belongs to the signal provider followed        
                { when: row => row.login === loginSub, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }}
            ],
        },
        {
            name: <div className="title-format">{t("master.Balance")}</div>,
            selector: row => row['balance'],
            sortable: true,
            center: true,
            wrap: true,
            compact: true,
            conditionalCellStyles: [    
                //group of validations to sett if this cell belongs to the signal provider followed        
                { when: row => row.login === loginSub, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }}
            ],
        },
        {
            name: <div className="title-format">{t("master.Credit")}</div>,
            selector: row => row['credit'] !== undefined ? row['credit'] : 0,
            sortable: true,
            center: true,
            wrap: true,
            compact: true,
            conditionalCellStyles: [    
                //group of validations to sett if this cell belongs to the signal provider followed        
                { when: row => row.login === loginSub, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }}
            ],
        },
        {
            name: <div className="title-format">{t("master.Equity")}</div>,
            selector: row => row['equity'],
            sortable: true,
            center: true,
            wrap: true,
            compact: true,
            conditionalCellStyles: [    
                //group of validations to sett if this cell belongs to the signal provider followed        
                { when: row => row.login === loginSub, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }}
            ],
        },
        {
            name: <div className="title-format">{t("master.PnL")}</div>,
            selector: row => row['pnl'],
            sortable: true,
            center: true,
            wrap: true,
            compact: true,
            conditionalCellStyles: [
                //group of validations to sett if this cell belongs to the signal provider followed
                { when: row => row.pnl < 0, style: { color: '#FF4862', '&:hover': { cursor: 'pointer', }, }, },
                { when: row => row.pnl > 0, style: { color: '#09B87D', '&:hover': { cursor: 'pointer', }, }, },
                { when: row => row.login === loginSub, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }},
            ],
        },
        {
            name: <div className="title-format">{t("master.Agent")}</div>,
            selector: row => row['agent'],
            sortable: true,
            center: true,
            wrap: true,
            compact: true,
            conditionalCellStyles: [    
                //group of validations to sett if this cell belongs to the signal provider followed        
                { when: row => row.login === loginSub, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }}
            ],
        },    
        {
            name: <div className="title-format">{t("master.Leverage")}</div>,
            selector: row => row['leverage'],
            sortable: true,
            center: true,
            wrap: true,
            conditionalCellStyles: [    
                //group of validations to sett if this cell belongs to the signal provider followed        
                { when: row => row.login === loginSub, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }}
            ],
        },
        {
            name: <div className="title-format">{t("master.Margin")}</div>,
            selector: row => row['margin'],
            sortable: true,
            center: true,
            wrap: true,
            conditionalCellStyles: [    
                //group of validations to sett if this cell belongs to the signal provider followed        
                { when: row => row.login === loginSub, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }}
            ],
        },
        {
            name: <div className="title-format">{t("master.Free_Margin")}</div>,
            selector: row => row['free_margin'],
            sortable: true,
            center: true,
            wrap: true,
            conditionalCellStyles: [    
                //group of validations to sett if this cell belongs to the signal provider followed        
                { when: row => row.login === loginSub, style: { 
                    background: "#5FF0A8", 
                    borderTopStyle: 'solid', 
                    borderTopWidth: '2px', 
                    borderTopColor: 'gray',
                    borderTopShadow: '10px',
                    borderBottomStyle: 'solid', 
                    borderBottomWidth: '2px', 
                    borderBottomColor: 'gray',

                }}
            ],
        },
        {
            name: <div className="title-format">{t("master.Action")}</div>,
            selector: row => row['button'],
            sortable: true,
            button: true,
            cell: (row) => {
                return (
                    <button
                    type="button" 
                    className="button-follower" 
                    onClick={(e) => handleSubTradesClick(e, row.login)}
                    >
                    {t("master.Sub Tickets")}
                    </button>
                );
            },
            style: {
                backgroundColor: '#F5F5F5',
            },
        } 

    ];

    //Validates if the history component was called by the user by click on history settings button, this adds an extra tag to the classname
    //to show or not the component
    let menu = props.triggerSubAccounts ? 'open' : 'closed';

    //Function handlers calls when user clicks on view settings button, this calls the props function TriggerFollowerSettings
    const handleSubTradesClick = (e, login) => {
        props.onOpenSubTrades(login);
        setloginSub(login);
    };

    const handleExportToExcel = () => {
        const tableHeaders = ["Login", "Name", "Balance", "Credit", "Equity", "PnL", "Margin", "Free Margin", "Leverage"];
        
        const tableRows = filteredData.map((row) => [
            row['login'] || "",          
            row['name'] || "",           
            row['balance'] || "",        
            row['credit'] || "",         
            row['equity'] || "",         
            row['pnl'] || "",            
            row['margin'] || "",         
            row['free_margin'] || "",    
            row['leverage'] || ""        
        ]);
    
        // Crea el contenido CSV
        let csvContent = "data:text/csv;charset=utf-8,";
    
        // Agrega encabezados
        csvContent += tableHeaders.join(",") + "\n";
    
        // Agrega filas
        tableRows.forEach((rowArray) => {
            csvContent += rowArray.join(",") + "\n";
        });
    
        // Crea un enlace para descargar el archivo
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "SubAccounts.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    

    return (
        <div className={`                     
                        ${'containerHistory-' + menu}
                    `}>
            <div className="labelTable">
                {t("subAccount.Sub Accounts")}
                {/* Botón de exportación */}
                <div className="filterZone">
                    <button 
                        className="exportButton" 
                        onClick={handleExportToExcel} 
                        title={t("master.Export to CSV")}
                    > 
                        <img 
                            src={downloadIcon} 
                            alt={t("master.Export Icon")} 
                            style={{ width: "15px", marginRight: "5px" }} // Espacio entre la imagen y el texto
                        />
                        Export
                    </button>
                    {/* Campo de filtro */}
                    <input
                        className=".filterboxSubAccounts"
                        type="text"
                        placeholder={t("master.Search...")}
                        value={filterValue}
                        onChange={handleFilterChange}
                    />
                </div>
                
            </div>
            
            <div className="table-responsive">
                <DataTable
                    columns={columnas}
                    data={filteredData}
                    customStyles={customStyles}
                    title={""}
                    pagination
                    paginationComponentOptions={{
                        rowsPerPageText: t("master.Rows per Page"),
                        rangeSeparatorText: t("master.of"),
                        selectAllRowsItem: true,
                        selectAllRowsItemText: t("master.All"),
                    }}
                    paginationServer
                    paginationTotalRows={props.totalSubAccounts}
                    onChangeRowsPerPage={props.SubAccountsPerRowsChange}
                    onChangePage={props.SubAccountsPageChange}
                    fixedHeader
                />
            </div>
        </div>
    );
}


export default SignalLogsTable;
